<template>
  <div class="data-list-container">
    <vs-tabs class="z-50">
      <vs-tab label="สิ่งอำนวยความสะดวกภายในโครงการ (Community Features)">
        <CondoComunityFeatures />
      </vs-tab>
      <vs-tab label="สิ่งอำนวยความสะดวกภายในห้อง (Room Features)">
        <CondoRoomFeatures />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import CondoRoomFeatures from './components/RoomFeatures'
import CondoComunityFeatures from './components/ComunityFeatures'
export default {
  components: {
    CondoRoomFeatures,
    CondoComunityFeatures,
  },
}
</script>
