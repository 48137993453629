<template>
  <div>
    <ValidationObserver ref="form" v-slot:default="context">
      <div class="vx-row">
        <div class="vx-col w-full">
          <form @submit.prevent="submit(context)">
            <FormLayout>
              <FormLabel>
                ชื่อสิ่งอำนวยความสะดวกภายในโครงการ <span class="text-danger">*</span>
              </FormLabel>
              <FormContent class="flex flex-col md:flex-row md:space-x-3 md:w-9/12 md:items-center">
                <div class="w-full md:w-8/12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="ชื่อสิ่งอำนวยความสะดวกภายในโครงการ"
                    rules="required"
                  >
                    <vs-input
                      v-model="formData.community_feature_title"
                      class="w-full"
                      name="community_feature_title"
                      placeholder="กรอกชื่อสิ่งอำนวยความสะดวกภายในโครงการ"
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm"
                    >
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="w-full md:w-4/12">
                  เช่น ล็อบบี้, สระว่ายน้ำ
                </div>
              </FormContent>
            </FormLayout>
            <vs-divider />
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดโดย</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formData.updated_by}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดวันที่</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel/>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <vs-button 
                  class="mr-2" 
                  @click="submit(context)">{{
                    buttonLabel
                  }}</vs-button>
              </div>
            </FormLayout>
          </form>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import get from 'lodash/get'
import find from 'lodash/find'
import { reactive, watch, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    onCreateSuccess: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => ({})
    },
    popupActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const notifyFn = useNotify(ctx)
    const initialFormData = computed(() => {
      return {
        uuid: props.data.uuid,
        id: props.data.id,
        community_feature_title: props.data.community_feature_title || '',
        updated_at: props.data.updated_at,
        created_by: props.data.created_by,
        updated_by: props.data.updated_by,
      }
    })
    const { addItem, formatDate } = useCrud(ctx, 'condoCommunityFeature', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })
    const formData = reactive(initialFormData)
    const submit = ({ validate }) => { 
      const existing = find(get(ctx.root.$store.state.condoCommunityFeature, 'items', []), (v => {
        return v.community_feature_title === formData.value.community_feature_title 
          && formData.value.id !== v.id
      }))

      if (existing) {
        return notifyFn.error({
          title: 'แจ้งเตือน',
          text: 'มีฟังก์ชันบ้านนี้อยู่ในระบบแล้ว กรุณาตรวจสอบ'
        }) 
      }
      addItem({ validate }, formData.value) 
    }
    const buttonLabel = computed(() => {
      return formData.value.id ? 'แก้ไข' : 'เพิ่ม'
    })

    watch(
      () => props.popupActive,
      (value) => {
        if (!value && ctx.refs.form && ctx.refs.form.reset) {
          ctx.refs.form.reset()
          formData.value.uuid = null
          formData.value.id = null
          formData.value.community_feature_title = ''
          formData.value.updated_at = null
          formData.value.created_by = null
          formData.value.updated_by = null
        }
      }
    )

    return {
      submit,
      formData,
      buttonLabel,
      formatDate,
    }
  },
}
</script>
