<template>
  <div id="lh-table">
    <custom-table 
      ref="table" 
      :max-items="itemsPerPage" 
      :data="items" 
      :searchKeys="searchKeys"
      pagination 
      search>
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <AddNewContent
          :on-click="onOpenAddPopup"
          name="condoRoomFeature"
          button-label="เพิ่มชื่อสิ่งอำนวยความสะดวกภายในห้อง"
        />
        <LhDropdownPerPage 
          :items="items" 
          :items-per-page="itemsPerPage" 
          :current-page="currentPage" 
          :queried-items="queriedItems"
          @click="(page) => itemsPerPage = page" /> 
      </div>
      <template slot="thead">
        <vs-th sort-key="room_feature_title">ชื่อสิ่งอำนวยความสะดวกภายในห้อง</vs-th>
        <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr">
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ tr.room_feature_title }}</td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ updatedAtDatetimeFormat(tr.updated_at) }}</td>
            <vs-td class="whitespace-no-wrap cursor-default">
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="updateData(tr)"
              />
              <feather-icon
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2 cursor-pointer"
                @click.stop="deleteData(tr.uuid)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref } from '@vue/composition-api'
import filter from 'lodash/filter'
import map from 'lodash/map'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  components: { AddNewContent, LhDropdownPerPage, CustomTable },
  props: {
    onOpenAddPopup: Function,
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'condoRoomFeature')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
      }))
    })

    const updateData = (houseData) => {
      props.onOpenAddPopup(houseData)
    }

    return {
      ...crudFunction,
      itemsPerPage: 20,
      items,
      updatedAtDatetimeFormat,
      updateData,
      searchKeys: ['room_feature_title']
    }
  },
}
</script>
