<template>
  <div>
    <vs-popup
      :active.sync="active"
      class="add_popup"
      title="ชื่อสิ่งอำนวยความสะดวกภายในห้อง"
    >
      <Form 
        :onCreateSuccess="onCreateSuccess" 
        :data="formData"
        :popup-active="active"
      />
    </vs-popup>
    <Table :onOpenAddPopup="onOpenAddPopup" />
  </div>
</template>

<script>
import Table from './Table'
import Form from './Form'
import { ref } from '@vue/composition-api'

export default {
  components: {
    Table,
    Form,
  },
  setup() {
    const active = ref(false)
    const formData = ref({})

    const onOpenAddPopup = (data) => {
      active.value = !active.value
      if (typeof data === 'object') {
        formData.value = {
          ...data
        }
      } else {
        formData.value = {}
      }
    }
    const onCreateSuccess = () => {
      active.value = false
    }

    return {
      active,
      onOpenAddPopup,
      onCreateSuccess,
      formData,
    }
  },
}
</script>
