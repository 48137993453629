<template>
  <div>
    <ValidationObserver ref="form" v-slot:default="context">
      <div class="vx-row">
        <div class="vx-col w-full">
          <form @submit.prevent="submit(context)">
            <div class="vx-row">
              <div class="vx-col w-3/12 flex items-center mb-base">
                ชื่อสิ่งอำนวยความสะดวกภายในห้อง*
              </div>
              <div class="vx-col w-8/12 mb-base">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ชื่อสิ่งอำนวยความสะดวกภายในห้อง"
                  rules="required"
                >
                  <vs-input
                    v-model="formData.room_feature_title"
                    class="w-full"
                    name="room_feature_title"
                    placeholder="เช่น ติดตั้งเครื่องปรับอากาศ,ตกแต่งเฟอร์นิเจอร์"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </ValidationProvider>
              </div>
            </div>
            <vs-divider />
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดโดย</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formData.updated_by}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดวันที่</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel/>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <vs-button 
                  class="mr-2" 
                  @click="submit(context)">{{
                    buttonLabel
                  }}</vs-button>
              </div>
            </FormLayout>
          </form>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { reactive, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useCrud from '@/use/useCrud'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    onCreateSuccess: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => ({})
    },
    popupActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const initialFormData = computed(() => {
      return {
        uuid: props.data.uuid,
        id: props.data.id,
        room_feature_title: props.data.room_feature_title || '',
        updated_at: props.data.updated_at,
        created_by: props.data.created_by,
        updated_by: props.data.updated_by,
      }
    })
    const { addItem, formatDate } = useCrud(ctx, 'condoRoomFeature', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })
    const formData = reactive(initialFormData)
    const submit = ({ validate }) => addItem({ validate }, formData.value)
    const buttonLabel = computed(() => {
      return formData.value.id ? 'แก้ไข' : 'เพิ่ม'
    })


    watch(
      () => props.popupActive,
      (value) => {
        if (!value && ctx.refs.form && ctx.refs.form.reset) {
          ctx.refs.form.reset()
          formData.value.uuid = null
          formData.value.id = null
          formData.value.room_feature_title = ''
          formData.value.updated_at = null
          formData.value.created_by = null
          formData.value.updated_by = null
        }
      }
    )
    return {
      submit,
      formData,
      buttonLabel,
      formatDate
    }
  },
}
</script>
